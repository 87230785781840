import { createSlice } from "@reduxjs/toolkit";

export const filtroSlices = createSlice({
  name: "filtro",
  initialState: {
    value: {
      limit: 10,
      direccion: "asc",
      orden: "nombre",
      offset: 0,
      estado: 0,
      search: {
        glosario: "",
        termino: "",
      },
    },
  },
  reducers: {
    changeLimit: (state, action) => {
      //
      state.value.limit = action.payload;
    },
    changeOffset: (state, action) => {
      //
      state.value.offset = action.payload;
    },
    changeData: (state, action) => {
      //
      state.value.search = action.payload;
    },

    clearOffset: (state, action) => {
      //
      state.value.offset = 0;
      state.value.search = action.payload;
    },

    changeOnly: (state, action) => {
      //

      state.value.estado = Math.random();
      state.value.offset = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeEvento,
  changeLimit,
  changeOffset,
  changeData,
  clearOffset,
  changeOnly,
} = filtroSlices.actions;

export default filtroSlices.reducer;
