import { createSlice } from "@reduxjs/toolkit";

export const tableroEventosSlices = createSlice({
  name: "tableroEventos",
  initialState: {
    value: [{}],
    dataTablaPag: [],
    nombre: "",
    titulo: "",
    filter: [],
    idTercero: "",
    body: {},
    objtos: [],
    tabs: [],
    priorizacion: [],
    ubicacion: [],
    fecha: {
      start_date: "",
      end_date: "",
    },
    fechaReporto: {
      start_date: "",
      end_date: "",
    },
    values: {
      limit: 10,
      offset: 0,
      orden: "",
      direccion: "",
      fila: 0,
    },
    numerico: [],
  },
  reducers: {
    addData: (state, action) => {
      //
      state.value = action.payload;
    },
    addNumerico: (state, action) => {
      //
      state.numerico = action.payload;
    },
    addNombre: (state, action) => {
      //
      state.nombre = action.payload;
    },
    addTitulo: (state, action) => {
      //
      state.titulo = action.payload;
    },
    addFilter: (state, action) => {
      //
      state.filter = action.payload;
    },
    addIdTercero: (state, action) => {
      //
      state.idTercero = action.payload;
    },

    addBody: (state, action) => {
      //
      state.body = action.payload;
    },
    addObjetos: (state, action) => {
      //
      state.objetos = action.payload;
    },
    addTabs: (state, action) => {
      //
      state.tabs = action.payload;
    },
    addFecha: (state, action) => {
      //
      state.fecha = action.payload;
    },
    addFechaReporto: (state, action) => {
      //
      state.fechaReporto = action.payload;
    },
    addPriorizacion: (state, action) => {
      //
      state.priorizacion = action.payload;
    },
    addUbicacion: (state, action) => {
      //
      state.ubicacion = action.payload;
    },
    changeEvento: (state, action) => {
      //
      state.values.orden = action.payload.name;
      state.values.direccion = action.payload.direction;
    },
    changeLimit: (state, action) => {
      //
      state.values.limit = action.payload;
      state.values.offset = state.values.fila * state.values.limit;
    },

    changeOffset: (state, action) => {
      //
      state.values.fila = action.payload;
      state.values.offset = state.values.fila * state.values.limit;
    },
    addDataTablaPag: (state, action) => {
      //
      state.dataTablaPag = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addData,
  addNombre,
  addTitulo,
  addFilter,
  addIdTercero,
  addBody,
  addObjetos,
  addTabs,
  addFecha,
  addFechaReporto,
  addPriorizacion,
  addUbicacion,
  changeOffset,
  changeLimit,
  changeEvento,
  addDataTablaPag,
  addNumerico,
} = tableroEventosSlices.actions;

export default tableroEventosSlices.reducer;
