import { createSlice } from "@reduxjs/toolkit";

export const partesSlices = createSlice({
  name: "partes",
  initialState: {
    value: [],
  },
  reducers: {
    addList: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addList } = partesSlices.actions;

export default partesSlices.reducer;
