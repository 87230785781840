import { createSlice } from "@reduxjs/toolkit";

export const unidadSlices = createSlice({
  name: "unidad",
  initialState: {
    value: [],
  },
  reducers: {
    addList2: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addList2 } = unidadSlices.actions;

export default unidadSlices.reducer;
