import { createSlice } from "@reduxjs/toolkit";

export const visorSlices = createSlice({
  name: "visor",
  initialState: {
    value: true,
  },
  reducers: {
    addLoad: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addLoad } = visorSlices.actions;

export default visorSlices.reducer;
