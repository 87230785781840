import { Alert } from "@mui/material";
import fetchIntercept from "fetch-intercept";

import jwt1 from "jwt-decode";
import NotFound from "./Error/NotFound";
import animationData from "./Error/notfound.json";

export const unregister = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here

    let jwt = "";
    let jwt2 = " ";
    const instance = localStorage.getItem("instance");
    const tkreact = "tkreact_" + instance;
    const route = localStorage.getItem("route");

    if (
      localStorage.getItem("tkreact") !== null &&
      localStorage.getItem("tkreact") !== "null" &&
      localStorage.getItem("tkreact") !== undefined &&
      localStorage.getItem("tkreact") !== "undefined" &&
      route === "adm_account" &&
      route !== "visor" &&
      route !== "visor-archivos" &&
      route !== "pdf_highlighter"
    ) {
      jwt = "Bearer " + localStorage.getItem("tkreact");
      jwt2 = localStorage.getItem("tkreact");
    } else {
      if (
        route !== "visor" &&
        route !== "visor-archivos" &&
        route !== "pdf_highlighter" &&
        route !== "ag_grid"
      ) {
        if (
          localStorage.getItem(tkreact) !== undefined &&
          localStorage.getItem(tkreact) !== null &&
          localStorage.getItem(tkreact) !== "null" &&
          localStorage.getItem(tkreact) !== "undefined"
        ) {
          jwt = "Bearer " + localStorage.getItem(tkreact);
          jwt2 = localStorage.getItem(tkreact);
        } else {
          jwt = "Bearer " + localStorage.getItem("tkreact");
          jwt2 = localStorage.getItem("tkreact");
        }
      }
    }

    try {
      if (jwt2 || jwt2 === null) {
        jwt1(jwt2);
      }

      const modifiedHeaders = new Headers(config.headers);
      modifiedHeaders.delete("instance");
      modifiedHeaders.delete("Authorization");

      if (
        route !== "visor" &&
        route !== "visor-archivos" &&
        route !== "pdf_highlighter"
      ) {
        modifiedHeaders.append("Authorization", jwt);
        modifiedHeaders.append("Cache-Control", "no-store");
      }

      config.headers = modifiedHeaders;

      return [url, config];
    } catch (e) {
      if (url.includes("http://10.0.0.34/sgi/api/v4/account")) {
        if (
          localStorage.getItem(tkreact) === null ||
          localStorage.getItem(tkreact) === undefined ||
          localStorage.getItem(tkreact) === "null" ||
          localStorage.getItem(tkreact) === "undefined"
        ) {
          return (
            <div align="center">
              <NotFound animationData={animationData} />
              <div style={{ marginTop: 3 }}>
                <Alert
                  variant="filled"
                  align="center"
                  sx={{ width: 300 }}
                  severity="error"
                >
                  Token inexistente 70
                </Alert>
              </div>
            </div>
          );
        } else {
          if (jwt1(jwt2)) {
            document.location.href = `/?route=adm_account`;
          } else {
            return (
              <div align="center">
                <NotFound animationData={animationData} />
                <div style={{ marginTop: 3 }}>
                  <Alert
                    variant="filled"
                    align="center"
                    sx={{ width: 300 }}
                    severity="error"
                  >
                    Token inválido 90
                  </Alert>
                </div>
              </div>
            );
          }
        }
      } else {
        const modifiedHeaders = new Headers(config.headers);
        modifiedHeaders.delete("instance");
        modifiedHeaders.delete("Authorization");

        return [url, config];
      }
    }
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});
