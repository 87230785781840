import { createSlice } from "@reduxjs/toolkit";

export const TableroEncuestasSlices = createSlice({
  name: "TableroEncuestas",
  initialState: {
    value: [],
    fieldsBoard: [],
    titulo: [],
    estado: true,
    body: [],
    asignacion: [],
    filter: [],
    fieldFilter: [],
    asignacionBool: "",
    asignacionService: false,
    componentes: {},
    defectoFilter: {},
  },
  reducers: {
    addComponents: (state, action) => {
      //
      state.componentes = action.payload;
    },
    addDataDinamic: (state, action) => {
      //
      state.value = action.payload;
    },
    addDataFieldsBoard: (state, action) => {
      //
      state.fieldsBoard = action.payload;
    },
    addDataTitle: (state, action) => {
      //
      state.titulo = action.payload;
    },
    addDataEstado: (state, action) => {
      //
      state.estado = action.payload;
    },
    addAssignments: (state, action) => {
      //
      state.asignacion = action.payload;
    },
    addFilter: (state, action) => {
      //
      state.filter = action.payload;
    },
    addFieldFilter: (state, action) => {
      //
      state.fieldFilter = action.payload;
    },
    addDefectoFilter: (state, action) => {
      //
      state.defectoFilter = action.payload;
    },
    addAsignacionBool: (state, action) => {
      //
      state.asignacionBool = action.payload;
    },
    addAsignacionService: (state, action) => {
      //
      state.asignacionService = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addDataDinamic,
  addDataFieldsBoard,
  addBody,
  addDataTitle,
  addDataEstado,
  addAssignments,
  addFilter,
  addAsignacionBool,
  addAsignacionService,
  addComponents,
  addFieldFilter,
  addDefectoFilter,
} = TableroEncuestasSlices.actions;

export default TableroEncuestasSlices.reducer;
