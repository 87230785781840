import { createSlice } from "@reduxjs/toolkit";

export const unidadesSlices = createSlice({
  name: "unidades",
  initialState: {
    value: [],
  },
  reducers: {
    addList: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addList } = unidadesSlices.actions;

export default unidadesSlices.reducer;
