import { createSlice } from "@reduxjs/toolkit";

export const origenesSlices = createSlice({
  name: "origenes",
  initialState: {
    value: [],
  },
  reducers: {
    addList: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addList } = origenesSlices.actions;

export default origenesSlices.reducer;
