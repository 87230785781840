import React from "react";
import Highcharts from "react-highcharts";

const DiagramaNoPercentBarras = ({ tablero, nombre }) => {
  const data = [];
  // const valores = [55.5, 85.5, 38.6, 44.9, 83.4];
  let contador = 0;
  const names = [];
  tablero.forEach((x) => {
    data.push({
      name: x.name,
      y: x.value
    });
    names.push(x.name);
    contador = contador + 1;
  });
  const config = {
    chart: {
      type: "column"
    },
    title: {
      text: nombre
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: names
    },
    yAxis: [
      {
        title: {
          text: " "
        }
      },
      {
        title: {
          text: " "
        },
        opposite: true
      }
    ],

    tooltip: {
      headerFormat: '<tr><td style="color: {series.color}"> <br/>',
      pointFormat: "{point.name}</b>: <b>{point.percentage:.1f}%"
    },
    plotOptions: {
      column: {
        showInLegend: false,

        dataLabels: {
          position: "top" // top, center, bottom
        }
      },
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: 0,

          format: "{point.y}"
        }
      },

      bar: {
        showInLegend: false,
        borderRadius: 0
      }
    },

    series: [
      {
        name: nombre,
        data: data,
        menu: true
      }
    ],
    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG"
    },

    exporting: {
      accessibility: {
        enabled: true
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true
        }
      },
      filename: "Indicador",
      sourceWidth: 1280,
      sourceHeight: 700,
      scale: 1,
      url: "https://l2yzp3xg9a.execute-api.us-east-1.amazonaws.com/prod/rasterizer",
      urlx: "/sgi/lib/php/exportar_img.php"
    }
  };

  return <Highcharts config={config} />;
};
export default DiagramaNoPercentBarras;
