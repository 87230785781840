import { createSlice } from "@reduxjs/toolkit";

export const cuentaSlices = createSlice({
  name: "cuenta",
  initialState: {
    value: [],
    endPoint: [],
    respuesta: [],
    profile: [],
    styleSignature: [],
    changeImage: [],
  },
  reducers: {
    addCuenta: (state, action) => {
      //
      state.value = action.payload;
    },
    addEndPoint: (state, action) => {
      //
      state.endPoint = action.payload;
    },
    addRespuesta: (state, action) => {
      //
      state.respuesta = action.payload;
    },
    addProfile: (state, action) => {
      //
      state.profile = action.payload;
    },
    styleSignature: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which dets changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.styleSignature = action.payload;
    },
    changeImage: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which dets changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.changeImage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addCuenta,
  addEndPoint,
  addProfile,
  addRespuesta,
  styleSignature,
  changeImage,
} = cuentaSlices.actions;

export default cuentaSlices.reducer;
