import { createSlice } from "@reduxjs/toolkit";

export const pruebaSlices = createSlice({
  name: "prueba",
  initialState: {
    value: [],
    detalle: [],
    estado: false,
    EliminarMasivo: [],
    disableEstado: true,
  },
  reducers: {
    addPruebas: (state, action) => {
      //
      state.value = action.payload;
    },
    addDetalle: (state, action) => {
      //
      state.detalle = action.payload;
    },
    addEstado: (state, action) => {
      //
      state.estado = action.payload;
    },
    EliminarMasivo: (state, action) => {
      //
      state.EliminarMasivo = action.payload;
    },
    disableEstado: (state, action) => {
      //
      state.disableEstado = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addPruebas,
  addDetalle,
  addEstado,
  disableEstado,
  EliminarMasivo,
} = pruebaSlices.actions;

export default pruebaSlices.reducer;
