import {
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Highcharts from "react-highcharts";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
// import Form from "react-bootstrap/Form";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useForm } from "react-hook-form";
// import ColorPicker from "./ColorPicker/ColorPicker";
import Menu from "./ColorPicker/Menu";

const drawerWidth = 700;

const DiagramaBarras = ({
  tablero,
  nombre,
  open,
  setOpen,
  value,
  setValue,
  select,
  setSelect,
  items,
  setItems,
  direccion,
  setDireccion,
  color,
  setColor
}) => {
  const { register } = useForm({ mode: "all" });
  const theme = useTheme();
  const [datos, setDatos] = useState([]);
  const [names, setNames] = useState("");

  // const [direccionBool, setDireccionBool] = useState(true);

  let subTitulo = "";

  if (value !== "") {
    if (value === "ascendente") {
      subTitulo =
        "Últimos " + items + " elementos ordenados por " + select.toLowerCase();
    } else {
      subTitulo =
        "Primeros " +
        items +
        " elementos ordenados por " +
        select.toLowerCase();
    }
  }

  const [dataSet, setDataSet] = useState([]);
  const dataSort = dataSet;

  const handleChangeSelect = (event) => {
    setSelect(event.target.value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeDireccion = (event) => {
    if (event.target.value === "bar") {
      setDireccion("bar");
    } else {
      setDireccion("column");
    }
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }));
  const data = [];
  let contador = 0;

  useEffect(() => {
    const names1 = [];

    if (datos.length <= items || items === "") {
      tablero.forEach((x) => {
        if (contador < items) {
          data.push({
            name: x.name,
            y: x.percent,
            prueba: x.value
          });
        } else if (items === "") {
          data.push({
            name: x.name,
            y: x.percent,
            prueba: x.value
          });
        }

        names1.push(x.name);

        contador = contador + 1;
      });

      setDatos(data);
      setDataSet(data);
      setNames(names1);
      contador = 0;
    } else {
      datos.forEach((x) => {
        if (contador < items) {
          data.push({
            name: x.name,
            y: x.y,
            prueba: x.prueba
          });
        } else if (items === "") {
          data.push({
            name: x.name,
            y: x.y,
            prueba: x.prueba
          });
        }

        names1.push(x.name);

        contador = contador + 1;
      });

      setDatos(data);
      setDataSet(data);
      setNames(names1);
      contador = 0;
    }
  }, [tablero, items]);

  useEffect(() => {
    let ejemploTablero = [];
    ejemploTablero = dataSort;

    if (select === "Nombre" && value === "ascendente") {
      ejemploTablero = ejemploTablero.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      const nombres = [];

      ejemploTablero.forEach((x) => {
        nombres.push(x.name);
      });
      setDatos(ejemploTablero);
      setNames(nombres);
    } else if (select === "Valor" && value === "ascendente") {
      ejemploTablero = ejemploTablero.sort(function (a, b) {
        if (parseInt(a.prueba) > parseInt(b.prueba)) {
          return 1;
        }
        if (parseInt(a.prueba) < parseInt(b.prueba)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      const nombres = [];

      ejemploTablero.forEach((x) => {
        nombres.push(x.name);
      });
      setDatos(ejemploTablero);
      setNames(nombres);
    }

    if (select === "Nombre" && value === "descendente") {
      ejemploTablero = ejemploTablero.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      ejemploTablero = ejemploTablero.reverse((a, b) => {
        return b.name - a.name;
      });
      const nombres = [];

      ejemploTablero.forEach((x) => {
        nombres.push(x.name);
      });
      setDatos(ejemploTablero);
      setNames(nombres);
    } else if (select === "Valor" && value === "descendente") {
      ejemploTablero = ejemploTablero.sort(function (a, b) {
        if (parseInt(a.prueba) > parseInt(b.prueba)) {
          return 1;
        }
        if (parseInt(a.prueba) < parseInt(b.prueba)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      ejemploTablero = ejemploTablero.reverse((a, b) => {
        return b.prueba - a.prueba;
      });

      const nombres = [];

      ejemploTablero.forEach((x) => {
        nombres.push(x.name);
      });

      setDatos(ejemploTablero);
      setNames(nombres);
    }
  }, [select, value, dataSet]);

  const config = {
    chart: {
      type: direccion
    },
    title: {
      text: nombre
    },
    subtitle: {
      text: items !== "" ? (select !== "" ? subTitulo : "") : ""
    },

    credits: {
      enabled: false
    },
    xAxis: {
      categories: names
    },
    yAxis: [
      {
        title: {
          text: " "
        }
      },
      {
        title: {
          text: " "
        },
        opposite: true
      }
    ],

    tooltip: {
      headerFormat:
        '<tr><td style="color: {series.color}"><b>{series.name} </td></b> <br/>',
      pointFormat: "{point.name}</b>: <b>{point.y:.1f}%"
    },
    plotOptions: {
      column: {
        showInLegend: false,
        dataLabels: {
          position: "top" // top, center, bottom
        },
        pointPadding: 0,
        borderWidth: 0,
        // groupPadding: 0,
        shadow: false
      },
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: 2,
          y: -20,
          format: "{point.y:.1f}%</b>: <b>({point.prueba})"
        }
      },

      bar: {
        showInLegend: false,
        borderRadius: 10
      }
    },

    series: [
      {
        name: nombre,
        data: datos,
        menu: true,
        color: color
      }
    ],
    style: {
      fontFamily: "bold"
    },
    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG"
    },

    exporting: {
      accessibility: {
        enabled: true
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true
        }
      },
      filename: "Indicador",
      sourceWidth: 1280,
      sourceHeight: 700,
      scale: 1,
      url: "https://l2yzp3xg9a.execute-api.us-east-1.amazonaws.com/prod/rasterizer",
      urlx: "/sgi/lib/php/exportar_img.php"
    }
  };

  const validate = (v) => {
    if (isNaN(v) || v === "") {
      setItems("");
    } else {
      if (v !== 0) {
        setItems(v);
      } else {
        setItems("");
      }
    }
  };

  return (
    <Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box"
          }
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Divider />
        </DrawerHeader>

        <List>
          <Divider />
          {[
            "Items",
            "Starred",
            "Send email",
            "Drafts",
            "Text",
            "Select",
            "Barras",
            "BarrasBox",
            "Color",
            "ColorPicker"
          ].map((text, index) =>
            text === "Items" ? (
              <ListItem key={text} disablePadding>
                <ListItemIcon></ListItemIcon>

                <ListItemText
                  sx={{ marginLeft: -5, marginRight: 1 }}
                  primary={"Cantidad de items"}
                />
              </ListItem>
            ) : text === "Starred" ? (
              <ListItem key={text} disablePadding>
                <ListItemIcon sx={{ marginLeft: 2, width: 130 }}>
                  {/* <Form autoComplete="off"> */}
                  <TextField
                    id="Item"
                    placeholder="Todos"
                    variant="standard"
                    sx={{
                      width: 20
                    }}
                    onChange={(e) => setItems(e.target.value)}
                    value={items}
                    inputProps={{ maxLength: 3 }}
                    {...register("item", {
                      validate: {
                        positive: (v) => validate(v)
                      }
                    })}
                  />
                  {/*  </Form> */}
                </ListItemIcon>
              </ListItem>
            ) : text === "Send email" ? (
              <ListItem sx={{ marginTop: 3 }} key={text} disablePadding>
                <ListItemIcon sx={{ marginLeft: 2 }}></ListItemIcon>

                <ListItemText
                  sx={{ marginLeft: -7, marginRight: 1 }}
                  primary={"Ordenar por"}
                />
              </ListItem>
            ) : text === "Drafts" ? (
              <ListItem key={text} disablePadding>
                <ListItemIcon sx={{ marginLeft: 2 }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={select}
                      onChange={handleChangeSelect}
                    >
                      <FormControlLabel
                        value="Nombre"
                        control={<Radio color={"primary"} />}
                        label="Nombre"
                      />
                      <FormControlLabel
                        value="Valor"
                        control={<Radio color={"primary"} />}
                        label="Valor"
                      />
                    </RadioGroup>
                  </FormControl>
                </ListItemIcon>
              </ListItem>
            ) : text === "Text" ? (
              <ListItem sx={{ marginTop: 3 }} key={text} disablePadding>
                <ListItemIcon sx={{ marginLeft: 2 }}></ListItemIcon>

                <ListItemText
                  sx={{ marginLeft: -7, marginRight: 1 }}
                  primary={"De manera"}
                />
              </ListItem>
            ) : text === "Select" ? (
              <ListItem key={text} disablePadding>
                <ListItemIcon sx={{ marginLeft: 2, width: 170 }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="ascendente"
                        control={<Radio color={"primary"} />}
                        label="Ascendente"
                      />
                      <FormControlLabel
                        value="descendente"
                        control={<Radio color={"primary"} />}
                        label="Descendente"
                      />
                    </RadioGroup>
                  </FormControl>
                </ListItemIcon>
              </ListItem>
            ) : text === "Barras" ? (
              <ListItem sx={{ marginTop: 3 }} key={text} disablePadding>
                <ListItemIcon sx={{ marginLeft: 2 }}></ListItemIcon>

                <ListItemText
                  sx={{ marginLeft: -7, marginRight: 1 }}
                  primary={"Visualizar como "}
                />
              </ListItem>
            ) : text === "BarrasBox" ? (
              <ListItem key={text} disablePadding>
                <ListItemIcon sx={{ marginLeft: 2, width: 170 }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={direccion}
                      onChange={handleChangeDireccion}
                    >
                      <FormControlLabel
                        value="bar"
                        control={<Radio color={"primary"} />}
                        label="Filas"
                      />
                      <FormControlLabel
                        value="column"
                        control={<Radio color={"primary"} />}
                        label="Columnas"
                      />
                    </RadioGroup>
                  </FormControl>
                </ListItemIcon>
              </ListItem>
            ) : text === "ColorPicker" ? (
              <Menu color={color} setColor={setColor} />
            ) : (
              /* <ColorPicker color={color} setColor={setColor} /> */
              ""
            )
          )}
        </List>
        <Divider />
      </Drawer>
      <Highcharts config={config} />
    </Box>
  );
};
export default DiagramaBarras;
