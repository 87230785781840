import { createSlice } from "@reduxjs/toolkit";

export const tableroSlices = createSlice({
  name: "tablero",
  initialState: {
    value: [],
    nombre: "",
    titulo: "",
    filter: [],
    idTercero: "",
    body: {},
    objtos: [],
  },
  reducers: {
    addData: (state, action) => {
      //
      state.value = action.payload;
    },
    addNombre: (state, action) => {
      //
      state.nombre = action.payload;
    },
    addTitulo: (state, action) => {
      //
      state.titulo = action.payload;
    },
    addFilter: (state, action) => {
      //
      state.filter = action.payload;
    },
    addIdTercero: (state, action) => {
      //
      state.idTercero = action.payload;
    },

    addBody: (state, action) => {
      //
      state.body = action.payload;
    },
    addObjetos: (state, action) => {
      //
      state.objetos = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addData,
  addNombre,
  addTitulo,
  addFilter,
  addIdTercero,
  addBody,
  addObjetos,
} = tableroSlices.actions;

export default tableroSlices.reducer;
