import { createSlice } from "@reduxjs/toolkit";

export const eventoSlices = createSlice({
  name: "evento",
  initialState: {
    value: {
      limit: 10,
      offset: 0,
      orden: "",
      direccion: "",
      fila: 0,
      search: {
        nombre: "",
        clase: "",
        usuario: "",
        estado: "activos",
        almecenamiento: "",
        fecha_desde: "",
        fecha_hasta: "",
      },
    },
  },
  reducers: {
    changeEvento: (state, action) => {
      //
      state.value.orden = action.payload.name;
      state.value.direccion = action.payload.direction;
    },
    changeLimit: (state, action) => {
      //
      state.value.limit = action.payload;
      state.value.offset = state.value.fila * state.value.limit;
    },

    changeOffset: (state, action) => {
      //
      state.value.fila = action.payload;
      state.value.offset = state.value.fila * state.value.limit;
    },
    changeData: (state, action) => {
      //
      state.value.search = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeEvento, changeLimit, changeOffset, changeData } =
  eventoSlices.actions;

export default eventoSlices.reducer;
