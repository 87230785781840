import { createSlice } from "@reduxjs/toolkit";

export const HighlighterSlices = createSlice({
  name: "Highlighter",
  initialState: {
    value: [],
    comentarios: [],
    arrComentarios: [],
  },
  reducers: {
    addHighlighter: (state, action) => {
      //
      state.value = action.payload;
    },
    addComentarios: (state, action) => {
      //
      state.comentarios = action.payload;
    },
    addArrComentarios: (state, action) => {
      //
      state.arrComentarios = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addHighlighter, addComentarios, addArrComentarios } =
  HighlighterSlices.actions;

export default HighlighterSlices.reducer;
