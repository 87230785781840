import { createSlice } from "@reduxjs/toolkit";

export const tableroPddSlices = createSlice({
  name: "pdd",
  initialState: {
    value: [],
    urlExcel: "",
    diagnosticsQuantity: {
      message: "",
      payload: {},
    },
    state: {
      message: "",
      payload: {
        diagnostics: [],
      },
    },
    metas: {
      message: "",
      payload: {
        goals: [],
      },
    },
    resultByRole: [],
    competencesByArea: [],
  },

  reducers: {
    addData: (state, action) => {
      //
      state.value = action.payload;
    },
    addUrlExcel: (state, action) => {
      //
      state.urlExcel = action.payload;
    },
    addResultByRole: (state, action) => {
      //
      state.resultByRole = action.payload;
    },

    addCompetencesByArea: (state, action) => {
      //
      state.competencesByArea = action.payload;
    },

    addDiagnosticsQuantity: (state, action) => {
      //
      state.diagnosticsQuantity = action.payload;
    },

    addState: (state, action) => {
      //
      state.state = action.payload;
    },
    addMetas: (state, action) => {
      //
      state.metas = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addData,
  addDiagnosticsQuantity,
  addState,
  addMetas,
  addResultByRole,
  addCompetencesByArea,
  addUrlExcel,
} = tableroPddSlices.actions;

export default tableroPddSlices.reducer;
