import { createSlice } from "@reduxjs/toolkit";

export const securityHubSlices = createSlice({
  name: "security",
  initialState: {
    value: [],
    score: [],
    summary: [],
  },
  reducers: {
    addSecurity: (state, action) => {
      //
      state.value = action.payload;
    },
    addScore: (state, action) => {
      //
      state.score = action.payload;
    },
    addSummary: (state, action) => {
      //
      state.summary = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSecurity, addScore, addSummary } = securityHubSlices.actions;

export default securityHubSlices.reducer;
