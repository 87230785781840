import { createSlice } from "@reduxjs/toolkit";

export const ReporteAtencionesSlices = createSlice({
  name: "reporteAtenciones",
  initialState: {
    value: {},
  },
  reducers: {
    addData: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addData } = ReporteAtencionesSlices.actions;

export default ReporteAtencionesSlices.reducer;
