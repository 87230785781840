import { createSlice } from "@reduxjs/toolkit";

export const listadoDocumentosSlices = createSlice({
  name: "listado",
  initialState: {
    value: {
      limit: 10,
      offset: 0,
      order: "",
      orientation: "",
      start_date: "",
      end_date: "",
      types: [],
      company: "",
      status: [],
      origins: [],
      term: "",
      categories: [],
    },
    total: "",
    offsetP: 10,
    fila: 0,
    estado: true,
    page: 0,
    surveyPD: [],
  },
  reducers: {
    changeEvento: (state, action) => {
      //
      state.value.order = action.payload.name;
      state.value.orientation = action.payload.direction;
    },
    changeLimit: (state, action) => {
      //
      state.value.limit = action.payload;
      state.value.offset = state.value.fila * state.value.limit;
    },

    changeOffset: (state, action) => {
      //
      state.value.fila = action.payload;
      state.value.offset = state.value.fila * state.value.limit;
    },
    changeData: (state, action) => {
      //
      state.value.search = action.payload;
    },
    changeTypes: (state, action) => {
      //
      state.value.types = action.payload;
    },
    key: (state, action) => {
      //
      state.value.key = action.payload;
    },
    categories: (state, action) => {
      //
      state.value.categories = action.payload;
    },
    Term: (state, action) => {
      //
      state.value.term = action.payload;
    },

    changeCompanies: (state, action) => {
      //
      state.value.company = action.payload;
    },
    changeFechIni: (state, action) => {
      //
      state.value.start_date = action.payload;
    },
    changeFechFin: (state, action) => {
      //
      state.value.end_date = action.payload;
    },
    changeUser: (state, action) => {
      //
      state.value.user = action.payload;
    },
    changeStatus: (state, action) => {
      //
      state.value.status = action.payload;
    },
    changeTotal: (state, action) => {
      //
      state.total = action.payload;
    },
    changeOffsetP: (state, action) => {
      //
      state.offsetP = action.payload;
    },
    changeOrigins: (state, action) => {
      //
      state.value.origins = action.payload;
    },
    changePages: (state, action) => {
      //
      state.page = action.payload;
    },
    changeSampleId: (state, action) => {
      //
      state.value.sample_id = action.payload;
    },
    changeFila: (state, action) => {
      //
      state.fila = action.payload;
    },
    surveyPD: (state, action) => {
      //
      state.surveyPD = action.payload;
    },

    changeEstado: (state, action) => {
      //
      state.estado = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeEvento,
  changeLimit,
  changeOffset,
  changeData,
  changeTotal,
  changeOffsetP,
  changeTypes,
  changeCompanies,
  changeOrigins,
  changeEstado,
  changeFechIni,
  changeFechFin,
  changeUser,
  changeFila,
  changePages,
  changeSampleId,
  surveyPD,
  Term,
  categories,
  key,
} = listadoDocumentosSlices.actions;

export default listadoDocumentosSlices.reducer;
