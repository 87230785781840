import { createSlice } from "@reduxjs/toolkit";

export const IpsDynamoSlices = createSlice({
  name: "IpsDynamo",
  initialState: {
    value: [],
    detalle: [],
    disableEstado: true,
    EliminarMasivo: [],
    estado: false,
    abbreviate: [],
    claseIp: {
      clase: "",
      clase_id: "",
      redirect: "",
    },
  },
  reducers: {
    addIpsDynamo: (state, action) => {
      //
      state.value = action.payload;
    },
    addDetalleDynamo: (state, action) => {
      //
      state.detalle = action.payload;
    },
    addClase: (state, action) => {
      //
      state.claseIp = action.payload;
    },
    addEstado: (state, action) => {
      //
      state.estado = action.payload;
    },
    EliminarMasivo: (state, action) => {
      //
      state.EliminarMasivo = action.payload;
    },
    disableEstado: (state, action) => {
      //
      state.disableEstado = action.payload;
    },
    abbreviate: (state, action) => {
      //
      state.abbreviate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addIpsDynamo,
  addDetalleDynamo,
  addClase,
  addEstado,
  EliminarMasivo,
  disableEstado,
  abbreviate,
} = IpsDynamoSlices.actions;

export default IpsDynamoSlices.reducer;
