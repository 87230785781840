import { createSlice } from "@reduxjs/toolkit";

export const glosariosSlices = createSlice({
  name: "glosarios",
  initialState: {
    value: [],
  },
  reducers: {
    addList1: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addList1 } = glosariosSlices.actions;

export default glosariosSlices.reducer;
