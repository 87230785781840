import { createSlice } from "@reduxjs/toolkit";

export const clasesSlices = createSlice({
  name: "clases",
  initialState: {
    value: [],
  },
  reducers: {
    addList: (state, action) => {
      //
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addList } = clasesSlices.actions;

export default clasesSlices.reducer;
