import React from "react";
import Highcharts from "highcharts";
const ReactHighcharts = require("react-highcharts");

require("highcharts/modules/exporting.js")(Highcharts);
require("highcharts/modules/export-data.js")(Highcharts);

const DiagramaNoPercentTorta = ({ tablero, nombre, type }) => {
  const data = [];
  // const valores = [55.5, 85.5, 38.6, 44.9, 83.4];
  let contador = 0;
  const names = [];

  tablero.forEach((x) => {
    data.push({
      name: x.name,
      y: x.value,
      sliced: true,
      selected: true
    });
    names.push(x.name);
    contador = contador + 1;
  });

  const config = {
    credits: {
      enabled: false
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: type
    },
    xAxis: {
      categories: names
    },
    title: {
      text: nombre
    },
    legend: {
      reversed: true
    },

    tooltip: {
      headerFormat: '<tr><td style="color: {series.color}"> <br/>',
      pointFormat: "{point.name}</b>: <b>{point.percentage:.1f}%"
    },
    plotOptions: {
      series: {
        dataLabels: {
          tittle: nombre,
          enabled: true
        }
      },

      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.1f} %",

          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black"
          }
        }
      }
    },

    series: [
      {
        name: nombre,
        colorByPoint: true,
        data: data,
        color: "#aabbcc"
      }
    ],

    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG"
    },

    exporting: {
      accessibility: {
        enabled: true
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true
        }
      },
      filename: "Indicador",

      scale: 1,
      url: "https://l2yzp3xg9a.execute-api.us-east-1.amazonaws.com/prod/rasterizer",
      urlx: "/sgi/lib/php/exportar_img.php"
    }
  };

  return <ReactHighcharts config={config} />;
};
export default DiagramaNoPercentTorta;
